@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  --menu-offset: 74px;
  --menu-offset-desktop: 88px;
}

@layer components {
  /* For Webkit-based browsers (Chrome, Safari and Opera) */
  .scrollbar-hide::-webkit-scrollbar {
      display: none;
  }

  /* For IE, Edge and Firefox */
  .scrollbar-hide {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
  }

  .main-menu-offset {
    padding-top: var(--menu-offset) !important;
    @screen lg {
      padding-top: var(--menu-offset-desktop) !important;
    }
  }

  .line-clamp-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }


  .line-clamp-3 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .bg-golden {
    background: linear-gradient(to bottom, #D4AF37 0%, #C5A028 100%);
  }

  .text-golden {
    @apply bg-golden;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  #headlessui-portal-root {
    position: relative;
    z-index: 50;
  }
}

@media screen and (min-width: 380px) and (max-width: 640px) {
  .container {
    max-width: 500px;
  }
}

html {
  /* scroll-behavior: smooth; */
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 14px;
  }
}

p {
  @apply leading-8;
}


input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

input[type="date"] {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type=checkbox] {
  @apply border-2 border-grey-700;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.prose a, .link {
  @apply text-brand no-underline hover:underline;
}

/* Google Maps Place Autocomplete */
.pac-container {
  box-shadow: none !important;
  overflow: unset !important;
  border-top: none !important;
  @apply z-30 py-4 px-0 mt-4 bg-transparent font-sans;

  &.pac-container-sticky-fix {
    position: fixed !important;
  }
  
  &:before {
    content: '';
    position: absolute;
    display: block;
    z-index: -1;

    @apply bg-white rounded-lg border-light-grey border-2 shadow-2xl bottom-0 left-0 right-0 top-0;
  }

  .pac-item {
    @apply text-gray-400 relative p-2 border-t-2 border-gray-100 cursor-pointer transition-colors;
    font-size: 0.8rem !important;
    padding-left: 49px !important;
    
    &:first-child {
      border-top: none;
    }

    &:hover, &.pac-item-selected {
      background-color: #ebf2fe;

      .pac-icon.pac-icon-marker:before {
        @apply bg-white;
      }
    }

    .pac-icon.pac-icon-marker {
      display: inline-block;
      font: normal normal normal 1rem 'selfstock-icons' !important;
      color: inherit;
      flex-shrink: 0;
      speak: none;
      text-transform: none;
      /* Better Font Rendering */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      background-image: none !important;
      width: auto !important;
      height: auto !important;
      vertical-align: middle;
      margin: -24px 0 0 0 !important;
      
      @apply p-4 text-gray-700 absolute left-0 transition-colors #{"!important"};
      top: 50%;

      &:before {
        content: "";
        @apply bg-light-grey rounded-full;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left:0;
        z-index: 0;
        width: 2em;
        height: 2em;
        margin: auto;
      }
      
      &:after {
        --item-width: 20px;
        content: "";
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgZmlsbD0iIzAwMDAwMCIgdmlld0JveD0iMCAwIDI1NiAyNTYiPjxwYXRoIGQ9Ik0xMjgsNjBhNDQsNDQsMCwxLDAsNDQsNDRBNDQuMDUsNDQuMDUsMCwwLDAsMTI4LDYwWm0wLDY0YTIwLDIwLDAsMSwxLDIwLTIwQTIwLDIwLDAsMCwxLDEyOCwxMjRabTAtMTEyYTkyLjEsOTIuMSwwLDAsMC05Miw5MmMwLDc3LjM2LDgxLjY0LDEzNS40LDg1LjEyLDEzNy44M2ExMiwxMiwwLDAsMCwxMy43NiwwLDI1OSwyNTksMCwwLDAsNDIuMTgtMzlDMjA1LjE1LDE3MC41NywyMjAsMTM2LjM3LDIyMCwxMDRBOTIuMSw5Mi4xLDAsMCwwLDEyOCwxMlptMzEuMywxNzQuNzFBMjQ5LjM1LDI0OS4zNSwwLDAsMSwxMjgsMjE2Ljg5YTI0OS4zNSwyNDkuMzUsMCwwLDEtMzEuMy0zMC4xOEM4MCwxNjcuMzcsNjAsMTM3LjMxLDYwLDEwNGE2OCw2OCwwLDAsMSwxMzYsMEMxOTYsMTM3LjMxLDE3NiwxNjcuMzcsMTU5LjMsMTg2LjcxWiI+PC9wYXRoPjwvc3ZnPg==");
        background-size: var(--item-width) var(--item-width);
        display: block;
        height: var(--item-width);
        width: var(--item-width);
        position: relative;
        z-index: 1;
      }
      
    }
    
    .pac-item-query {
      font-size: 1rem !important;
      @apply block #{"!important"};
      @apply text-gray-500 font-sans;
      line-height: 30px;
    }
  
    & > span {
      display:block;
      line-height: 1;
      @apply font-mono;
    }
    
    .pac-matched {
      @apply font-medium text-gray-900;
    }
  }

  &.pac-logo:after {
    padding: 0 !important;
    @apply mr-4 mt-4;
  }
}

 /* Store locator center card focused  */
.center-card-focused > div {
  @apply shadow-2xl ring-inset ring-4 ring-brand/60;
}